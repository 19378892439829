import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-1",staticStyle:{"max-width":"800px"},attrs:{"id":"test","fluid":"","box-sizing":"content-box"}},[(!_vm.loading)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12","align":"center"}},[_vm._v(" "+_vm._s(_vm.companyName)+" - "+_vm._s(_vm.$t('systemsetting-5'))+" "),_c('br'),_c('h1',[_vm._v(" "+_vm._s(_vm.$t('customerportalIndex-choose'))+" ")])]),_c(VCol,{attrs:{"cols":"12","md":"12","align":""}},_vm._l((_vm.pNumberList),function(pNumber){return _c(VRow,{key:pNumber.pNumber,staticClass:"border background-hover",on:{"click":function($event){return _vm.choosePNumber(pNumber)}}},[_c(VCol,{attrs:{"cols":"4"}},[_c('span',[_c('b',[_vm._v(" "+_vm._s(pNumber.name))])])]),_c(VCol,{attrs:{"cols":"5","align":"center"}},[_c('b',[_vm._v("P-nummer:")]),_c('br'),_vm._v(" "+_vm._s(pNumber.pNumber)+" ")]),_c(VCol,{attrs:{"cols":"3","align":"end"}},[_c(VBtn,{staticClass:"align-center justify-center",attrs:{"icon":"","large":"","color":"blue"},on:{"click":function($event){return _vm.choosePNumber(pNumber)}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-arrow-right-circle-outline ")])],1)],1)],1)}),1)],1):_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"d-flex flex-column "},[_c(VProgressCircular,{attrs:{"size":70,"width":7,"color":"#0060e6","indeterminate":""}}),_c('div',[_vm._v(_vm._s(_vm.$t('customerportal-loggingIn')))])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }