<template>
    <v-container
        id="test"
        style="max-width: 800px;"
        fluid
        class="pa-1"
        box-sizing="content-box"
    >
        <v-row
            v-if="!loading"
        >
            <v-col

                cols="12"
                md="12"
                align="center"
            >
                {{ companyName }} - {{ $t('systemsetting-5') }} <br>
                <h1>
                    {{ $t('customerportalIndex-choose') }}
                </h1>
            </v-col>
            <v-col
                cols="12"
                md="12"
                align=""
            >
                <v-row
                    v-for="pNumber in pNumberList"
                    :key="pNumber.pNumber"
                    class="border background-hover"
                    @click="choosePNumber(pNumber)"
                >
                    <v-col cols="4">
                        <span><b>
                            {{ pNumber.name }}</b></span>
                    </v-col>
                    <v-col
                        cols="5"
                        align="center"
                    >
                        <b>P-nummer:</b> 
                        <br>

                        {{ pNumber.pNumber }}
                    </v-col>
                    <v-col
                        cols="3"
                        align="end"
                    >
                        <v-btn
                            icon
                            large
                            color="blue"
                            class="align-center justify-center"
                            @click="choosePNumber(pNumber)"
                        >
                            <v-icon dark>
                                mdi-arrow-right-circle-outline
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row
            v-else
            justify="center"
        >
            <v-col
                cols="12"
            >
                <div class="d-flex justify-center">
                    <div
                        class="d-flex flex-column "
                    >
                        <v-progress-circular
                            :size="70"
                            :width="7"
                            color="#0060e6"
                            indeterminate
                        />
                        <div>{{ $t('customerportal-loggingIn') }}</div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default{
    data(){
        return{
            pNumberList: [],
            loading: false
        }
    },
    computed: {    
        color(){
            return 'background-color: ' + process.env.VUE_APP_COMPANY_COLOR + ';'
        },
        darkMode(){
            return process.env.VUE_APP_COMPANY_DARK === '1'
        },
        companyName(){
            return process.env.VUE_APP_TERMS_COMPANY_NAME
        },
        img(){
            return process.env.VUE_APP_COMPANY_LOGO
        },    
        ...mapGetters({
            getEmailConfirmed: 'getEmailConfirmed',
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber'
        })
    },
    created(){
        this.loading = true
        Promise.all([
            this.FetchUserCvrNumber(),
            this.FetchPNumberListByUser()
        ])
            .then(results => {
                //cvr info results
                this.selectedCvrNumber = results[0].cvrNumber
                this.selectedCvrName = results[0].name

                if(localStorage.getItem('selectedPNumber') !== null){
                    localStorage.removeItem('selectedPNumber')
                }
                this.setSelectedCvrNumber(this.selectedCvrNumber)

                this.pNumberList = results.length > 1 ? results[1].pNumberList : []
                if(this.getSelectedPNumber === null){
                    this.setSelectedPNumber(this.pNumberList[0].pNumber)
                }

                //GET OR CREATE THE CURRENT USER
                this.FetchUser({
                    pNumber: this.getSelectedPNumber
                })
                    .then(() => {
                        this.loading = false
                    })
            })   
    },
    methods: {
        choosePNumber(pNumber){
            this.setSelectedPNumber(pNumber.pNumber)
            this.setSelectedPNumberName(pNumber.name)

            localStorage.setItem('selectedPNumberName', pNumber.name)
            localStorage.setItem('selectedPNumber', pNumber.pNumber)
            
            this.$router.push({ name: 'EntranceList' })
        },
        ...mapMutations({
            setSelectedCvrNumber: 'setSelectedCvrNumber',
            setSelectedPNumber: 'setSelectedPNumber',
            setSelectedPNumberName: 'setSelectedPNumberName'
        }),
        ...mapActions({
            FetchUser: 'FetchUser',
            FetchUserCvrNumber: 'FetchUserCvrNumber',
            FetchPNumberListByUser: 'FetchPNumberListByUser',
        })
    }
}
</script>

<style>
.border {
  border-bottom: solid black 1px;
}
.background-hover:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}
.header-col {
  color: white;
  padding-bottom: 10px;
}
</style>
